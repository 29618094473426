<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">{{ $t('pages.media.title') }}</h2>

        <div class="d-flex flex-wrap my-1">
            <a class="newMediaBtn btn btn-sm btn-primary align-self-center" v-on:click="newMedia">
                <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr009.svg"/>
                </span>
                {{ $t('btn.new') }}
            </a>
            <div class="m-0">
                <el-select class="ms-4 w-75px" v-model="paginationObj.pageSize" @change="setItemsPerPage">
                    <el-option v-for="(item, itemIndex) in perPageItems" :key="itemIndex" :value="item">{{ item }}</el-option>
                </el-select>
            </div>
        </div>
    </div>
    <div class="row g-6 g-xl-9">
        <div class="col-sm-6 col-md-4 col-xxl-3" v-for="(file, fileIndex) in table.data" :key="fileIndex">
            <div class="card h-100">
                <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                    <a :href="file.public_url" target="_blank" class="text-gray-800 text-hover-primary d-flex flex-column">
                        <div class="symbol mb-6">
                            <img class="h-60px w-auto max-w-150px object-fit-contain" :src="getFilePreview(file)" alt=""/>
                        </div>

                        <div class="fs-5 fw-bolder mb-2">{{ file.name }}</div>
                    </a>
                    <div class="fs-7 fw-bold text-gray-700 mt-auto mb-2">({{ file.size_text }})</div>
                    <div class="fs-7 fw-bold text-gray-400">{{ $moment(file.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</div>

                    <!--<div class="separator border-gray-200 mt-8"></div>

                    <div class="d-flex flex-center flex-wrap mt-8">
                        <a class="btn btn-sm btn-danger">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/arrows/arr011.svg"/>
                            </span>
                            {{ $t('btn.delete') }}
                        </a>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="d-block d-sm-flex flex-wrap pt-10 justify-content-center justify-content-sm-between align-items-center">
            <div class="fs-6 fw-bold text-gray-700 pb-3 pb-sm-0 text-center">{{ paginationText }}</div>

            <el-pagination
                class="text-center"
                v-model:current-page="paginationObj.current"
                @current-change="paginationEvent"
                :page-size="paginationObj.pageSize"
                layout="prev, pager, next"
                :total="paginationObj.total"
                :pager-count="3"
                :hide-on-single-page="true"
                background>
            </el-pagination>
        </div>
    </div>

    <div class="modal fade" id="kt_modal_media" ref="mediaModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_change_password_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_change_password_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="mediaForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_change_password_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.media.upload.title') }}</label>
                                <el-form-item prop="file">
                                    <el-upload :file-list="form.fileList" :accept="acceptFiles" class="upload-file" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag multiple>
                                        <el-icon class="el-icon--upload">
                                             <span class="svg-icon">
                                                 <inline-svg src="/media/icons/duotune/files/fil022.svg" />
                                             </span>
                                        </el-icon>
                                        <div class="el-upload__text">{{ $t('pages.media.upload.dragFile')}}</div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            paginationObj: {},
            perPageItems: [10, 25, 50, 100],
            filePreview: {
                pathPattern: '/media/svg/files/%s',
                default: 'upload.svg',
                list: {
                    video: 'video.svg',
                    sound: 'audio.svg',
                    document: {
                        pdf: 'pdf.svg',
                        doc: 'doc.svg',
                        docx: 'doc.svg'
                    },
                    compressed: {
                        zip: "zip.svg",
                        rar: "rar.svg"
                    }
                }
            },
            form: {
                title: '',
                loading: false,
                fileList: [],
                uploadList: [],
                updateStatus: false,
                data: {}
            },
        }
    },
    computed: {
        table() {
            return this.$store.state.file.table;
        },
        paginationText() {
            let text = '';

            if (Object.keys(this.paginationObj).length) {
                let rangeStart = (this.paginationObj.current - 1) * this.paginationObj.pageSize;
                let rangeEnd = this.paginationObj.current * this.paginationObj.pageSize;

                text = this.sprintf(this.$t('pages.media.showingPagePattern'), [
                    this.paginationObj.total,
                    (rangeStart == 0 ? 1 : rangeStart),
                    (rangeEnd < this.paginationObj.total ? rangeEnd : this.paginationObj.total)
                ]);
            }

            return text;
        },
        acceptFiles(){
            let accepts = [];

            for(let group in this.$root.fileSupported.group) {
                let prefix = ((group === 'image') ? 'image/' : '.');

                accepts.push(this.$root.acceptFileSupported(group, prefix));
            }

            return accepts.join(', ');
        },
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.media"), [this.$t("menu.mediaManagement")]);
        this.$store.dispatch('file/get', {
            page: {},
            filterData: {
                sort: 'created_at:desc',
            }
        });
    },
    methods: {
        newMedia(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.uploadList = [];
            this.form.fileList = [];
            this.form.title = this.$t("pages.media.newMedia");
            this.showModal(this.$refs.mediaModal);
        },
        refreshTable(){
            this.$store.dispatch("file/refresh");
        },
        onSubmit(){
            this.$refs.mediaForm.validate((valid) => {
                if(valid) {
                    if(this.form.updateStatus) {
                        this.submitForm();
                    } else {
                        this.submitFormUpload();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormUpload(){
            if(!(this.form.uploadList.length > 0)){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.media.warnings.files"),
                })
                return false;
            }

            this.form.loading = true;

            const formData = new FormData();

            this.form.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                    this.hideModal(this.$refs.mediaModal);
                }, () => {
                    this.form.loading = false;
                });
            }).catch(error => {
                if(error.response.data.message == 'invalid_file_type') {
                    error.response.data.message = this.$t('pages.media.responseMessages.invalidFileType');
                }

                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm(){
           /* if(this.prepareTeacherFormData()) {
                if(this.form.data.id) {
                    this.axios.put(this.endpoints['module_teacher'] + '/' + this.form.data.id, this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.addTeacherModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                }else {
                    this.axios.post(this.endpoints['module_teacher'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.addTeacherModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                }
            }*/
        },
        setItemsPerPage() {
            this.paginationObj.current = 1;
            this.paginationEvent();
        },
        paginationEvent() {
            this.$store.dispatch("file/get", {
                page: this.paginationObj,
                filterData: {
                    sort: 'created_at:desc',
                }
            });
        },
        getFilePreview(file) {
            let preview = null;
            let fileSupported = this.$root.fileSupported.group;

            for (let group in fileSupported) {
                if (fileSupported[group].includes(file.extension)) {
                    if (group == 'image') {
                        return file.thumb.small;
                    } else if (['video', 'sound'].includes(group)) {
                        preview = this.filePreview.list[group];
                    } else if(this.filePreview.list[group] && this.filePreview.list[group][file.extension]){
                        preview = this.filePreview.list[group][file.extension];
                    }
                    break;
                }
            }

            return preview ? this.sprintf(this.filePreview.pathPattern, [preview ?? this.filePreview.default]) : false;
        },
        handleChangeFile(file, fileList){
            this.form.fileList = fileList;
            this.form.uploadList = fileList;
        },
    },
    watch: {
        'table.pagination': {
            handler: function (newValue) {
                this.paginationObj = newValue;
            },
            deep: true
        }
    }
}
</script>

<style>
.newMediaBtn{
    height: 40px;
    line-height: 2;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    background: none;
    border-radius: 0.475rem;
    font-weight: 500;
    font-size: 1.075rem;
    font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
    background-color: #fff;
}

.el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    line-height: 25px !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #009ef7;
}
.upload-file .el-upload {
    width: 100%;
}

.upload-file .el-upload .el-upload-dragger{
    width: 100%;
}
.svg-icon svg {
    height: 1em;
    width: 1em;
}
</style>